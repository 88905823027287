import React, { Fragment } from 'react';

const Footer = ({ footer }) => {

    return (
        <>
            <section className="footer-section pt-5 pb-5">
                <div className="container sm-w85 mt-4 mb-2 footer-section-container">
                    <div className="row justify-content-between xs-left">
                        <div className="col-lg-9 col-md-8">
                            <div className="row">
                                {footer.map((footerOne, index) => {
                                    if (footerOne.node.section == 'contentA') {
                                        return (
                                            <Fragment key={index}>
                                                <div className="col-lg-2 col-md-3 mb-5 footer-image">
                                                    <img src={footerOne.node.footerImage.file.url} alt="" />
                                                </div>
                                                <div className="col-lg-5 col-md-9">
                                                    <h2 className="footer-title">{footerOne.node.footerHeadline}</h2>
                                                    <p className="address">
                                                        {footerOne.node.footerAddress}
                                                    </p>
                                                    <div className="contact">
                                                        <a href={"tel:" + footerOne.node.footerMobileNumber}>{footerOne.node.footerMobileNumber}</a> |{' '}
                                                        <a href={"tel:" + footerOne.node.footerLandline}>{footerOne.node.footerLandline}</a>
                                                        <br />
                                                        <a href={"mailto:" + footerOne.node.footerEmail}>
                                                            {footerOne.node.footerEmail}
                                                        </a>{' '}
                                                        |
                                                        <span className="social-media">
                                                            <a href={footerOne.node.facebookLink} target="_blank">
                                                                <i className="fa fa-facebook-square"></i>
                                                            </a>
                                                            <a href={footerOne.node.instagramLink} target="_blank">
                                                                <i className="fa fa-instagram"></i>
                                                            </a>
                                                        </span>
                                                    </div>
                                                    <p className="schedules">
                                                        {footerOne.node.footerOpeningDates} <br />
                                                        {footerOne.node.footerOpeningHours}
                                                    </p>
                                                </div>
                                            </Fragment>
                                        )
                                    }

                                })}

                                <div className="col-lg-5 col-md-9">
                                    {footer.map((footerTwo, index) => {
                                        if (footerTwo.node.section == 'contentB') {
                                            return (
                                                <Fragment key={index}>
                                                    <h2 className="footer-title">{footerTwo.node.footerHeadline}</h2>
                                                    <div className="contact">
                                                        {footerTwo.node.footerMobileNumber &&
                                                            <a href={"tel:" + footerTwo.node.footerMobileNumber}>{footerTwo.node.footerMobileNumber}</a>
                                                        }
                                                        | {' '}
                                                        {footerTwo.node.footerLandline &&
                                                        <>
                                                           
                                                            <a href={"tel:" + footerTwo.node.footerLandline}>{footerTwo.node.footerLandline}</a>
                                                        </>
                                                        }
                                                        <br />
                                                        {footerTwo.node.footerEmail &&
                                                            <a href={"mailto:" + footerTwo.node.footerEmail}>
                                                                {footerTwo.node.footerEmail}
                                                                {' '}
                                                            </a>
                                                        }
                                                        |
                                                        <span className="social-media">
                                                            {footerTwo.node.facebookLink &&
                                                                <a href={footerTwo.node.facebookLink} target="_blank">
                                                                    <i className="fa fa-facebook-square"></i>
                                                                </a>
                                                            }

                                                            {footerTwo.node.instagramLink &&
                                                                <a href={footerTwo.node.instagramLink} target="_blank">
                                                                    <i className="fa fa-instagram"></i>
                                                                </a>
                                                            }
                                                        </span>
                                                    </div>
                                                    <p className="schedules">
                                                        {footerTwo.node.footerOpeningDates &&
                                                            <>
                                                            {footerTwo.node.footerOpeningDates}
                                                            <br />
                                                            </>
                                                        }
                                                        {footerTwo.node.footerOpeningHours &&
                                                            footerTwo.node.footerOpeningHours
                                                        }
                                                    </p>
                                                </Fragment>
                                            )
                                        }

                                        if (footerTwo.node.section == 'contentC') {
                                            return (
                                                <Fragment key={index}>
                                                    <h2 className="footer-title">{footerTwo.node.footerHeadline}</h2>
                                                    <div className="contact">
                                                        {footerTwo.node.footerMobileNumber &&
                                                            <a href={"tel:" + footerTwo.node.footerMobileNumber}>{footerTwo.node.footerMobileNumber}</a>
                                                        
                                                        }

                                                        {footerTwo.node.footerLandline &&
                                                        <>
                                                            |{' '}
                                                            <a href={"tel:" + footerTwo.node.footerLandline}>{footerTwo.node.footerLandline}</a>
                                                        </>
                                                        }
                                                        <br />
                                                        {footerTwo.node.footerEmail &&
                                                            <a href={"mailto:" + footerTwo.node.footerEmail}>
                                                                {footerTwo.node.footerEmail}
                                                                {' '}
                                                            </a>
                                                        }
                                                        |
                                                        <span className="social-media">
                                                            {footerTwo.node.facebookLink &&
                                                                <a href={footerTwo.node.facebookLink} target="_blank">
                                                                    <i className="fa fa-facebook-square"></i>
                                                                </a>
                                                            }

                                                            {footerTwo.node.instagramLink &&
                                                                <a href={footerTwo.node.instagramLink} target="_blank">
                                                                    <i className="fa fa-instagram"></i>
                                                                </a>
                                                            }
                                                        </span>
                                                    </div>
                                                    <p className="schedules">
                                                        {footerTwo.node.footerOpeningDates &&
                                                            <>
                                                            {footerTwo.node.footerOpeningDates}
                                                            <br />
                                                            </>
                                                        }
                                                        {footerTwo.node.footerOpeningHours &&
                                                            footerTwo.node.footerOpeningHours
                                                        }
                                                    </p>
                                                </Fragment>
                                            )
                                        }
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 mt-4">
                            <div className="newsletter pt-2">
                                <p className="mb-2">Sign up for updates</p>
                                <form
                                    action="https://gmail.us5.list-manage.com/subscribe/post?u=8888c103642d087afb1f42fef&amp;id=72e829b037&amp;SIGNUP=Newsletter%20Signup"
                                    method="post"
                                >
                                    <div className="newsletter-form">
                                        <input name="EMAIL" type="email" required />
                                        <button>
                                            <img src="/images/arrow-right.png" alt="" height="20" />
                                        </button>
                                    </div>
                                    <p className="copyright">
                                        &copy; {new Date().getFullYear()} Palacio de Memoria
                                        <br />
                                        Website designed by Serious Studio
                                    </p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
};

export default Footer;
