import React, { useState } from 'react';
import '../scss/main.scss';

const Layout = ({ children, color }) => {
  const [loading, setLoading] = useState(true);
  if (typeof document !== 'undefined') {
    const interval = setInterval(() => {
      if (document.readyState === 'complete') {
        setLoading(false);
        clearInterval(interval);
      }
    }, 100);
  }

  return (
    <div
      className={`page-loader ${color === 'lightBrown' ? 'brown-bg' : null}`}
      style={{ opacity: loading ? 0 : 1 }}
    >
      {children}
    </div>
  );
};

export default Layout;
