import React from 'react';
import { navigate } from '@reach/router';

export default class NavSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navMenus: this.props.navMenus,
      navSticky: false,
      submenuTarget: '',
      subOpen: false,
      isOpen: false,
    };

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickListener = this.handleClickListener.bind(this);
  }

  componentDidMount = () => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', this.handleScroll, { passive: true });
      document.addEventListener('mouseover', this.handleClickListener);
    }
  };

  componentWillUnmount = () => {
    if (typeof window !== 'undefined') {
      window.removeEventListener('scroll', this.handleScroll);
      document.removeEventListener('mouseover', this.handleClickListener);
    }
  };

  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  handleScroll = () => {
    if (typeof window !== 'undefined') {
      const isNavSticky = window.scrollY > 1;
      this.setState({ navSticky: isNavSticky });
    }
  };

  navHandler = () => {
    if (typeof window !== 'undefined') {
      if (!this.state.isOpen) {
        document.body.classList.add('backdrop');
        window.scrollTo(0, 0);
      } else {
        document.body.classList.remove('backdrop');
      }

      this.setState({ isOpen: !this.state.isOpen });
      this.setState({ subOpen: false });
      this.setState({ submenuTarget: '' });
    }
  };

  handleSubMenu = (e, slug) => {
    const { submenuTarget } = this.state;
    const currentTarget = e.currentTarget.id;

    if(submenuTarget === currentTarget){
      navigate(`/${slug}`);
    }else{
      setTimeout(() => {
        this.setState({ subOpen: true });
        this.setState({ submenuTarget: currentTarget });
      }, 100);
    }
  };

  handleSubMenuHover = (e) => {
    if(window.innerWidth > 991){
      const currentTarget = e.currentTarget.id;
      setTimeout(() => {
        this.setState({ submenuTarget: currentTarget });
      }, 100);
    }
  };

  hideSubmenu = () => {
    if(window.innerWidth > 991){
      this.setState({ submenuTarget: '' });
    }
  }

  handleClickListener(event) {
    if(window.innerWidth > 991){
      const targetInside =
        this.wrapperRef && this.wrapperRef.contains(event.target);
      this.setState({ subOpen: targetInside });
      if (!targetInside) {
        this.setState({ submenuTarget: '' });
      }
    }
  }

  render() {
    const { navMenus, isOpen, navSticky, subOpen, submenuTarget } = this.state;
    const { navTheme } = this.props;
    const currentSlug =
      typeof window !== `undefined` ? window.location.pathname : null;

    return (
      <nav
        role="navigation"
        className={`navbar navbar-expand-lg ${navTheme} ${isOpen &&
          'open'} ${navSticky && 'sticky'}`}
      >
        <div className="container nav-container">
          {/* LOGO FOR DESKTOP */}
          <a className="navbar-brand main-logo" href="/">
            <img
              src={
                navTheme === 'nav-white'
                  ? '/images/logo-red.png'
                  : '/images/logo.png'
              }
              alt="Logo"
              height="110"
            />
          </a>
          {/* LOGO FOR MOBILE */}
          <a className="navbar-brand logo-mobile" href="/">
            <img
              src={
                navTheme === 'nav-white'
                  ? '/images/mobile-logo-red.png'
                  : '/images/mobile-logo-white.png'
              }
              alt="Logo"
              height="110"
            />
          </a>
          <button
            className={`navbar-toggler ${isOpen && 'open'} ${navTheme}`}
            type="button"
            onClick={this.navHandler}
          >
            <img
              src={
                isOpen && navTheme === 'nav-white'
                  ? '/images/red-close.svg'
                  : navTheme === 'nav-white'
                    ? '/images/red-burger-menu.svg'
                    : isOpen
                      ? '/images/close.svg'
                      : '/images/burger-menu.svg'
              }
              alt="menu"
            />
          </button>

          <div
            className={`collapse navbar-collapse ${isOpen && 'open'}`}
            id="navbarSupportedContent"
            ref={this.setWrapperRef}
            onMouseLeave={() => this.hideSubmenu()}
          >
            <ul className="navbar-nav ml-auto">
              {navMenus.map(menu => {
                const slugRoot = currentSlug && currentSlug.split('/')[1];
                const { id, slug, pageTitle } = menu.node;
                const { submenu } = menu.node;

                if (submenu) {
                  return (
                    <li
                      className={
                        slugRoot && slugRoot.includes(slug)
                          ? 'active nav-item'
                          : 'nav-item'
                      }
                      key={id}
                    >
                      <a
                        id={slug}
                        onClick={(e) => this.handleSubMenu(e, slug)}
                        onMouseEnter={(e) => this.handleSubMenuHover(e)}
                      >
                        {pageTitle}
                      </a>

                      <div className={slug === submenuTarget ? 'nav-mobile-only show' : 'nav-mobile-only'}>
                        {navMenus.map((menu, key) => {
                          const { slug, pageTitle } = menu.node;
                          const { submenu } = menu.node;

                          if (subOpen && slug === submenuTarget) {
                            return (
                              <ul className="navbar-nav ml-auto submenu" key={key}>
                                <div className="nav-submenu">
                                  {submenu.map((sub, i) => {
                                    var subslug = sub.replace(/\s+/g, '-').toLowerCase();
                                    return (
                                      <li className="nav-item" key={key+i}>
                                        <a href={`/${pageTitle.toLowerCase()}/${subslug}`}>
                                          {sub}
                                        </a>
                                      </li>
                                    );
                                  })}
                                </div>
                              </ul>
                            );
                          }
                        })}
                      </div>
                    </li>
                  );
                } else {
                  return (
                    <li
                      className={
                        slugRoot && slugRoot.includes(slug)
                          ? 'active nav-item'
                          : 'nav-item'
                      }
                      key={id}
                    >
                      <a href={`/${slug}`} onMouseEnter={() => this.hideSubmenu()}>{pageTitle}</a>
                    </li>
                  );
                }
              })}
              <li>
                <ul className="nav-social-media">
                  <li className="nav-item social-media">
                    <a href="https://www.facebook.com/thepalaciodememoria/">
                      <span className="fa fa-facebook-square"></span>
                    </a>
                  </li>
                  <li className="nav-item social-media">
                    <a href="https://www.instagram.com/palacio.de.memoria/">
                      <span className="fa fa-instagram"></span>
                    </a>
                  </li>
                  <li className="nav-item social-media">
                    <a href="/contact">
                      <span className="fa fa-envelope"></span>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>


            {navMenus.map((menu, key) => {
              const { slug, pageTitle } = menu.node;
              const { submenu } = menu.node;

              if (subOpen && slug === submenuTarget) {
                return (
                  <ul className="navbar-nav ml-auto submenu nav-menu-desktop-only" key={key}>
                    <div className="nav-submenu">
                      {submenu.map((sub, i) => {
                        var subslug = sub.replace(/\s+/g, '-').toLowerCase();
                        return (
                          <li className="nav-item" key={key+i}>
                            <a href={`/${pageTitle.toLowerCase()}/${subslug}`}>
                              {sub}
                            </a>
                          </li>
                        );
                      })}
                    </div>
                  </ul>
                );
              }
            })}
          </div>
        </div>
      </nav>
    );
  }
}
